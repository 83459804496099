import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid';

const features = [
    {
        icon: CloudArrowUpIcon,
        title: "Push to deploy",
        description: "Deploy your rules instantly with automated rule execution pipelines."
    },
    {
        icon: LockClosedIcon,
        title: "AI-driven decision-making",
        description: "Optimize processes with intelligent automation and real-time adaptability."
    },
    {
        icon: ServerIcon,
        title: "Secure and scalable",
        description: "Ensure compliance and reliability with enterprise-grade infrastructure."
    }
];

function FeatureItem({ icon: Icon, title, description }) {
    return (
        <li className="flex gap-x-3">
            <Icon aria-hidden="true" className="mt-1 size-5 flex-none text-indigo-600" />
            <span>
                <strong className="font-semibold text-gray-900">{title}.</strong> {description}
            </span>
        </li>
    );
}

export default function AboutUs() {
    return (
        <main className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
            <section className="max-w-4xl mx-auto">
                <div className="flex">
                    {[
                        { text: "Automate", emoji: "⚡" },
                        { text: "Adapt", emoji: "📡" },
                        { text: "Accelerate", emoji: "🏎" }
                    ].map(({ text, emoji }, index) => (
                        <p key={index} className="text-base font-semibold text-indigo-600 mr-5">
                            {emoji} {text}.
                        </p>
                    ))}
                </div>
                <h1 className="mt-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    EaseRule AI
                </h1>
                <h3 className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                    The Future of Intelligent Decision-Making
                </h3>
                <p className="mt-6 text-lg text-gray-700 leading-relaxed">
                    Empower your business with AI-driven rule automation that evolves with your needs.
                    EaseRule AI enables dynamic rule creation, execution, and optimization—so you can
                    focus on innovation while we handle the logic.
                </p>
            </section>

            <section className="mt-12 max-w-4xl mx-auto text-gray-700">
                <p>
                    Experience the best-in-class tools for automation, security, and performance.
                    Automate your infrastructure and eliminate manual tasks to scale effortlessly.
                </p>
                <ul role="list" className="mt-8 space-y-8 text-gray-600">
                    {features.map((feature, index) => (
                        <FeatureItem key={index} {...feature} />
                    ))}
                </ul>
            </section>

            <section className="mt-16 max-w-4xl mx-auto">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900">No infrastructure worries.</h2>
                <p className="mt-6 text-gray-700 leading-relaxed">
                    Embrace the power of serverless technology for faster deployments, reduced costs, and seamless scalability. Let EaseRule AI handle the complexity while you focus on driving business growth.
                </p>
                <a href="/developer-docs" className="mt-4 inline-block text-indigo-600 font-semibold">
                    Explore our Developer Docs →
                </a>
            </section>
        </main>
    );
}
