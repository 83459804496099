import { PaperClipIcon } from '@heroicons/react/20/solid'

export default function PreviewRule({ rule }) {

    const downloadJsonFile = () => {

        const jsonContent = JSON.stringify({
            ruleName: rule?.ruleName,
            ruleDescription: rule?.ruleName,
            ruleJson: rule?.description
        }, null, 2);


        const blob = new Blob([jsonContent], { type: 'application/json' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${rule?.ruleName}.json`;  // File will be named based on the rule name
        link.click(); // Trigger the download
    };


    return (
        <div>
            <div className="px-4 sm:px-0">
                <h3 className="text-base/7 font-semibold text-gray-900">Rule Information</h3>
                <p className="mt-1 max-w-2xl text-sm/6 text-gray-500">Rule Description & JSON</p>
            </div>
            <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm/6 font-medium text-gray-900">Rule name</dt>
                        <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{rule?.ruleName}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm/6 font-medium text-gray-900">About</dt>
                        <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {rule?.description}
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm/6 font-medium text-gray-900">Rule JSON</dt>
                        <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                                <li className="flex items-center justify-between py-4 pr-5 pl-4 text-sm/6">
                                    <div className="flex w-0 flex-1 items-center">
                                        <PaperClipIcon aria-hidden="true" className="size-5 shrink-0 text-gray-400" />
                                        <div className="ml-4 flex min-w-0 flex-1 gap-2"
                                        >
                                            <span className="truncate font-medium">max_transaction_limit.json</span>
                                            <span className="shrink-0 text-gray-400">4.5mb</span>
                                        </div>
                                    </div>
                                    <div className="ml-4 shrink-0">
                                        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500"
                                            onClick={downloadJsonFile}
                                        >
                                            Download
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    )
}
