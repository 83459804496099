import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './component/home/Home.jsx';
import Login from './component/login/Login.jsx';
import AboutUs from './component/about-us/about-us.jsx';
import EmailDashboard from './component/email/EmailDashboard.jsx'
import Navigation from './component/navigation/Navigation.jsx'
import Dashboard from './component/dashboard/dashboard.jsx'

function App() {
  return (
    <div className="App">
      <Router>
        {/* <Navigation /> */}
        <Routes>
          <Route path="//dashboard" element={<Dashboard />} />
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/dashboard' element={<Dashboard />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
