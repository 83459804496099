import { useState } from "react";
import PreviewRule from "./preview-rule";

export default function CreateRule() {
    const [isPreview, setIsPreview] = useState(false);
    const [rule, setRule] = useState({
        ruleName: "",
        description: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRule((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <div>
            {isPreview ? (
                <div>
                    <PreviewRule rule={rule} />
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                            type="button"
                            onClick={() => setIsPreview(false)}
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-500 focus-visible:outline-2 focus-visible:outline-indigo-600"
                        >
                            Edit Rule
                        </button>
                    </div>
                </div>
            ) : (
                <form>
                    <div className="space-y-12">
                        <div className="border-b border-gray-900/10 pb-12">
                            <h2 className="text-base/7 font-semibold text-gray-900">Profile</h2>
                            <p className="mt-1 text-sm/6 text-gray-600">
                                This information will be displayed publicly, so be careful what you share.
                            </p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-4">
                                    <label htmlFor="ruleName" className="block text-sm/6 font-medium text-gray-900">
                                        Rule Name
                                    </label>
                                    <div className="mt-2">
                                        <div className="flex items-center rounded-md bg-white pl-3 outline-1 -outline-offset-1 outline-gray-300 focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
                                            <div className="shrink-0 text-base text-gray-500 select-none sm:text-sm/6">
                                                your-work-space/
                                            </div>
                                            <input
                                                id="ruleName"
                                                name="ruleName"
                                                type="text"
                                                placeholder="max_transaction_limit"
                                                className="block min-w-0 grow py-1.5 pr-3 pl-1 text-base text-gray-900 placeholder:text-gray-400 focus:outline-none sm:text-sm/6"
                                                value={rule.ruleName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="description" className="block text-sm/6 font-medium text-gray-900">
                                        Describe your rule
                                    </label>
                                    <div className="mt-2 border border-dashed">
                                        <textarea
                                            id="description"
                                            name="description"
                                            rows={3}
                                            className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 outline-2 outline-offset-2 outline-indigo-600 sm:text-sm/6"
                                            placeholder={
                                                "Create a rule to send an alert whenever a transaction amount exceeds 500 Indian rupees."
                                            }
                                            value={rule.description}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button type="button" className="text-sm/6 font-semibold text-gray-900">
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={() => setIsPreview(true)}
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-xs hover:bg-indigo-500 focus-visible:outline-2 focus-visible:outline-indigo-600"
                        >
                            Preview
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
}
